import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, NgModule, Optional, SkipSelf } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import {  MatTableModule } from '@angular/material/table';

import { TokenInterceptor } from '@core/interceptor/token.interceptor';
import { IconService } from '@core/services';

const iconConfigurationFactory = (iconService: IconService): (() => Promise<any>) => {
  return () =>
    new Promise((resolve) => {
      iconService.registerCustomIcons();
      resolve(true);
    });
};

@NgModule({
  imports: [MatIconModule],
  providers: [
    IconService,
    {
      provide: APP_INITIALIZER,
      useFactory: iconConfigurationFactory,
      deps: [IconService],
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    }
  ]
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error('CoreModule is already loaded. Import it in the AppModule only');
    }
  }
}
