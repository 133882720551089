import { createFeatureSelector, createSelector } from '@ngrx/store';
import { getRouterSelectors, RouterReducerState, SerializedRouterStateSnapshot } from '@ngrx/router-store';

import { RootState } from '@app/store/root.state';
import { findParam } from '@core/utils';

export const selectRouterState = createFeatureSelector<RootState, RouterReducerState<SerializedRouterStateSnapshot>>('router');

export const {
  selectCurrentRoute, // select the current route
  selectFragment, // select the current route fragment
  selectQueryParams, // select the current route query params
  selectQueryParam, // factory function to select a query param
  selectRouteParams, // select the current route params
  selectRouteParam, // factory function to select a route param
  selectRouteData, // select the current route data
  selectUrl // select the current url
} = getRouterSelectors(selectRouterState);

export const selectNestedRouteParam = createSelector(selectRouterState, (router, props) => findParam(router?.state?.root, props.name));
export const selectNestedRouteParamFactory = (paramName: string) => createSelector(selectRouterState, (router) => findParam(router?.state?.root, paramName));
