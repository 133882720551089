import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {  MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { SpinnerComponent } from '@shared/components/spinner/spinner.component';

@NgModule({
  declarations: [SpinnerComponent],
  imports: [CommonModule, MatProgressSpinnerModule],
  exports: [SpinnerComponent],
  providers: []
})
export class SpinnerModule {}
