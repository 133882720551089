import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { MatMenuModule } from '@angular/material/menu';

import { LayoutComponent } from '@app/layout/components/layout/layout.component';
import { TemplateDirective, TemplateModule } from '@shared/index';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { UserInfoComponent } from '@app/layout/components/user-info/user-info.component';
import { AccessDeniedComponent } from '@app/layout/pages/access-denied/access-denied.component';
import { ErrorComponent } from '@app/layout/components/error/error.component';

@NgModule({
  declarations: [LayoutComponent, UserInfoComponent, ErrorComponent, AccessDeniedComponent],
  imports: [BrowserAnimationsModule, CommonModule, MatToolbarModule, MatIconModule, MatButtonModule, MatSidenavModule, MatListModule, RouterModule, TemplateModule, MatMenuModule, FlexLayoutModule],
  exports: [LayoutComponent, TemplateDirective],
  providers: []
})
export class LayoutModule {}
